import vert from './text.vert';
import frag from './text.frag';
import mat4 from 'gl-mat4';
import { getTextPixels } from './getTextInfo';

export async function createTextCommand(
  regl,
  version = 0,
  text = 'starry night',
  fontSize = '200px',
) {
  const {
    pixelsData,
    size: { width, height },
  } = await getTextPixels(text, { fontSize, fontFamily: 'Pingfang', gap: 4 });

  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;
  const pixelRatio = window.devicePixelRatio || 1;

  const finalPosition = [];
  const perCount = version === 0 ? 33 : 23;
  const spread = 0.02;
  const size = version === 0 ? 30 : 10;
  const minSize = version === 0 ? 6 : 4;

  const scatterCount = 0;

  pixelsData.forEach(({ pos }) => {
    const x = ((pos[0] * 2) / width - 1) * (width / viewportWidth);
    const y = ((-pos[1] * 2) / height + 1) * (height / viewportHeight);
    for (let i = 0; i < perCount; i++) {
      const angle = Math.random() * 2 * Math.PI * i; // 0 ~ 2PI 的角度随机
      const amplitude = Math.random() * 0.35 + 0.65; // 0.8 ~ 1 的长度随机
      const x1 = x + spread * Math.cos(angle) * amplitude * (viewportHeight / viewportWidth);
      const y1 = y + spread * Math.sin(angle) * amplitude;
      const z = Math.random();
      const r = Math.random();
      finalPosition.push([x1, y1, z, r]);
    }
  });

  for (let i = 0; i < scatterCount; i++) {
    const angle = Math.random() * 2 * Math.PI * i;
    const amplitude = Math.random() * 0.4 + 0.1;
    const z = Math.random();
    const r = Math.random();
    finalPosition.push([amplitude * Math.cos(angle), amplitude * Math.sin(angle), z, r]);
  }

  // 像素起始随机坐标
  const originPosition = finalPosition.map(() => {
    const angle = Math.random() * 2 * Math.PI;
    const amplitude = Math.random();
    return [amplitude * Math.sin(angle), amplitude * Math.cos(angle), 0, 0].map((n) => n * 20);
  });

  const finalPosBuffer = regl.buffer({
    data: finalPosition,
    usage: 'dynamic',
    type: 'float',
  });

  // const count = finalPosition.length;
  // const offset = (count - scatterCount) * 3 * 4;
  // let updatePosBuffer = finalPosition.slice(count - scatterCount, count);
  // window.setTimeout(() => {
  //   window.setInterval(() => {
  //     finalPosBuffer.subdata(updatePosBuffer, offset);
  //     updatePosBuffer = updatePosBuffer.map((i) => i.map((j) => (j -= 0.01)));
  //   }, 62.5);
  // }, 1500);

  return regl({
    vert,
    frag,
    attributes: {
      a_finalPosition: {
        buffer: finalPosBuffer,
      },
      a_originPosition: originPosition,
    },
    uniforms: {
      u_p: regl.prop('p'),
      size,
      minSize,
      u_t: ({ time }) => time,
    },
    count: finalPosition.length,
    primitive: 'points',
  });
}
