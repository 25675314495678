<template lang="pug">
.wrapper
  video.video(ref='video' playsinline)
  canvas.canvas(ref='canvas')
  .poster(v-if='isShow') 加载中...
</template>

<script>
window.__DEBUG__ = false;

import createREGL from 'regl';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { createTextCommand } from '@/commands/star-text';
import Stats from 'three/examples/jsm/libs/stats.module.js';
// import { initDetect } from './vic-detect';
import { initDetect } from './yoha-detect';

// trigger

export default {
  name: 'Track-Text',
  setup() {
    const canvas = ref(null);
    const video = ref(null);
    const isShow = ref(true);

    /** @type {ReturnType<typeof createREGL>} */
    let regl;

    onMounted(async () => {
      const stats = new Stats();
      canvas.value.parentElement.appendChild(stats.dom);

      const onResize = () => {
        canvas.value.width = window.innerWidth * 2;
        canvas.value.height = window.innerHeight * 2;
      };
      window.addEventListener('resize', onResize);
      onResize();

      regl = createREGL({
        canvas: canvas.value,
      });

      const draw = await createTextCommand(regl, canvas.value, '执楠科技', '80px');

      const setup = regl({
        depth: { enable: false },
      });
      let p = 0;
      let dir = 0;
      await initDetect({
        video: video.value,
        update: (_, info) => {
          const {
            coordinates: [wRation, hRatio],
            hasHand,
            fist,
          } = _;
          const w = canvas.value.offsetWidth;
          const h = canvas.value.offsetHeight;

          canvas.value.style.transform = `
            translate3d(
              ${((wRation * w) / 2 - w / 2) * -0.5}px,
              ${((hRatio * h) / 2 - h / 2) * 0.5}px,
              0) scale(${1 - hRatio})`;

          setup({}, () => {
            if (!hasHand || fist < 1) {
              dir = -1;
            } else {
              dir = 1;
            }
            p += dir * 0.18;
            p = Math.min(Math.max(p, 0), 1);
            draw({ p });
          });
          stats.update();
          // {
          //   if (info.bbox) {
          //     canvas.value.style.transform = `
          //       translate3d(
          //         ${info.bbox[0] + info.bbox[2] / 2 - canvas.value.offsetWidth / 2}px,
          //         ${info.bbox[1] + info.bbox[3] / 2 - canvas.value.offsetHeight / 2}px,
          //         0px
          //       )
          //       scale(${info.bbox[2] / canvas.value.offsetWidth})
          //     `;
          //   }
          //   setup({}, () => {
          //     if (info.label === 'closed' || (info.label === 'open' && info.score < 0.75)) {
          //       dir = -1;
          //     } else {
          //       dir = 1;
          //     }
          //     p += dir * 0.18;
          //     p = Math.min(Math.max(p, 0), 1);
          //     draw({ p });
          //   });
          // }
        },
      });
      if (isShow.value) isShow.value = false;
    });

    onBeforeUnmount(() => regl?.destroy());

    return { canvas, video, isShow };
  },
};
</script>

<style lang="stylus" scoped>
.wrapper
  width 100vw
  height 100vh
  position relative
  overflow hidden

  .video
  .canvas
  .poster
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    object-fit cover
    transform-origin center

  .poster
    background purple
    opacity 0.6
    color white
    display flex
    justify-content center
    align-items center
    font-weight 700
    font-size 10vw
</style>
