export function captureCamera(cameraParam = {}) {
  const { targetFPS = 60, width = 1920, height = 1080 } = cameraParam;
  /**
   * @type {MediaStreamConstraints}
   */
  const videoConfig = {
    audio: false,
    video: {
      facingMode: 'environment',
      frameRate: {
        ideal: targetFPS,
      },
    },
  };
  return navigator.mediaDevices.getUserMedia(videoConfig);
}

export function drawToVideo(video, stream) {
  return new Promise((resolve, reject) => {
    video.srcObject = stream;
    video.onloadedmetadata = () => {
      console.log('video.videoWidth', video.videoWidth);
      console.log('video.videoHeight', video.videoHeight);
      video.width = window.screen.width;
      video.height = window.screen.height;
      video.play().then(() => {
        console.log('video played');
      });
      resolve('webcam successfully initiated.');
    };
    video.onerror = (e) => {
      reject(e);
    };
  });
}
