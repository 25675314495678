import * as yoha from '@handtracking.io/yoha';
import { captureCamera, drawToVideo } from './utils';

function transRes(res) {
  const transCoord = (coords) => {
    let wRatio = coords[3][0] + coords[19][0],
      hRatio = coords[20][1] + coords[11][1];

    return [wRatio, hRatio];
  };

  return {
    coordinates: transCoord(res.coordinates), //transCoord(res.coordinates),
    fist: 1 - +Number(res.poses.fistProb).toFixed(2),
    hasHand: res.isHandPresentProb > 0.5,
  };
}

export async function initDetect({ video, update }) {
  const modelFiles = await yoha.DownloadMultipleYohaTfjsModelBlobs(
    '/assets/models/handtrack/box/model.json',
    '/assets/models/handtrack/lan/model.json',
    (rec, total) => {
      console.log('Download progress: ' + (rec / total) * 100 + '%');
    },
  );

  const stream = await captureCamera();
  console.log('stream ready');

  await drawToVideo(video, stream);
  console.log('video ready');

  yoha.StartTfjsWebglEngine(
    {
      mirrorX: true,
      padding: 0,
    },
    video,
    modelFiles,
    (res) => {
      if (update) {
        update(transRes(res));
      }
    },
  );
}
